// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-common-stock-js": () => import("./../../../src/pages/common-stock.js" /* webpackChunkName: "component---src-pages-common-stock-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reports-2nd-2021-js": () => import("./../../../src/pages/reports/2nd-2021.js" /* webpackChunkName: "component---src-pages-reports-2nd-2021-js" */),
  "component---src-pages-reports-index-js": () => import("./../../../src/pages/reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */)
}

